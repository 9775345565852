<template>
 <div>

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="183" height="183" viewBox="0 0 183 183">
  <defs>
    <clipPath id="clip-path11">
      <circle id="Ellipse_164" data-name="Ellipse 164" cx="55.5" cy="55.5" r="55.5" transform="translate(0)" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-211">
      <rect id="Rectangle_211" data-name="Rectangle 211" width="111" height="111" transform="translate(0 0)" fill="none"/>
    </clipPath>
  </defs>
  <g id="icon_bunq" transform="translate(-151 -2781)">
    <rect id="Rectangle_197" data-name="Rectangle 197" width="183" height="183" transform="translate(151 2781)" fill="#fff"/>
    <g id="Group_407" data-name="Group 407" transform="translate(-597.592 2805.988)">
      <g id="Group_406" data-name="Group 406" transform="translate(785.591 0.012)" clip-path="url(#clip-path11)">
        <g id="Group_405" data-name="Group 405" transform="translate(0 0)">
          <g id="bunq_App_Icon_Square" data-name="bunq App Icon Square">
            <rect id="Rectangle_198" data-name="Rectangle 198" width="111" height="111" transform="translate(0 0)" fill="none"/>
            <g id="Group_404" data-name="Group 404">
              <g id="Group_403" data-name="Group 403" clip-path="url(#clip-path-211)">
                <g id="Group_402" data-name="Group 402" transform="translate(0 -0.002)">
                  <g id="Group_394" data-name="Group 394">
                    <g id="Group_382" data-name="Group 382" transform="translate(0 0.002)">
                      <rect id="Rectangle_199" data-name="Rectangle 199" width="18" height="111" transform="translate(0 0)" fill="#238647"/>
                    </g>
                    <g id="Group_383" data-name="Group 383" transform="translate(8.709 0.002)">
                      <rect id="Rectangle_200" data-name="Rectangle 200" width="19" height="111" transform="translate(0.291 0)" fill="#2f9b47"/>
                    </g>
                    <g id="Group_384" data-name="Group 384" transform="translate(18.288 0.002)">
                      <rect id="Rectangle_201" data-name="Rectangle 201" width="19" height="111" transform="translate(-0.288 0)" fill="#62b64f"/>
                    </g>
                    <g id="Group_385" data-name="Group 385" transform="translate(26.997 0.002)">
                      <rect id="Rectangle_202" data-name="Rectangle 202" width="19" height="111" transform="translate(0.003 0)" fill="#89cc53"/>
                    </g>
                    <g id="Group_386" data-name="Group 386" transform="translate(36.577 0.002)">
                      <rect id="Rectangle_203" data-name="Rectangle 203" width="18" height="111" transform="translate(0.422 0)" fill="#3db8ad"/>
                    </g>
                    <g id="Group_387" data-name="Group 387" transform="translate(46.157 0.002)">
                      <rect id="Rectangle_204" data-name="Rectangle 204" width="18" height="111" transform="translate(-0.157 0)" fill="#3394d7"/>
                    </g>
                    <g id="Group_388" data-name="Group 388" transform="translate(54.866 0.002)">
                      <rect id="Rectangle_205" data-name="Rectangle 205" width="19" height="111" transform="translate(0.134 0)" fill="#2872bc"/>
                    </g>
                    <g id="Group_389" data-name="Group 389" transform="translate(64.445 0.002)">
                      <rect id="Rectangle_206" data-name="Rectangle 206" width="19" height="111" transform="translate(-0.445 0)" fill="#1d5c84"/>
                    </g>
                    <g id="Group_390" data-name="Group 390" transform="translate(74.025 0.002)">
                      <rect id="Rectangle_207" data-name="Rectangle 207" width="18" height="111" transform="translate(-0.025 0)" fill="#993233"/>
                    </g>
                    <g id="Group_391" data-name="Group 391" transform="translate(82.734 0.002)">
                      <rect id="Rectangle_208" data-name="Rectangle 208" width="19" height="111" transform="translate(0.266 0)" fill="#e13030"/>
                    </g>
                    <g id="Group_392" data-name="Group 392" transform="translate(92.314 0.002)">
                      <rect id="Rectangle_209" data-name="Rectangle 209" width="19" height="111" transform="translate(-0.314 0)" fill="#f28825"/>
                    </g>
                    <g id="Group_393" data-name="Group 393" transform="translate(101.363)">
                      <rect id="Rectangle_210" data-name="Rectangle 210" width="9.239" height="110.872" fill="#f5c836"/>
                    </g>
                  </g>
                  <g id="Group_401" data-name="Group 401" transform="translate(14.647 38.805)">
                    <g id="Group_400" data-name="Group 400">
                      <g id="Group_399" data-name="Group 399">
                        <g id="Group_395" data-name="Group 395" transform="translate(22.176 7.853)">
                          <path id="Path_472" data-name="Path 472" d="M1158.214,454.5h3.7v9.7a8.315,8.315,0,1,1-16.631,0v-9.7h3.7v9.7a4.641,4.641,0,1,0,9.239,0Z" transform="translate(-1145.279 -454.503)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                        <g id="Group_396" data-name="Group 396" transform="translate(42.524 7.391)">
                          <path id="Path_473" data-name="Path 473" d="M1356.418,468.02h3.7v-9.7a8.315,8.315,0,1,0-16.631,0v9.7h3.7v-9.7a4.641,4.641,0,1,1,9.239,0Z" transform="translate(-1343.483 -450.003)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                        <g id="Group_397" data-name="Group 397">
                          <path id="Path_474" data-name="Path 474" d="M932.493,403.411h-3.234V395.1h.014c-.009-.153-.014-.307-.014-.462V378h3.7v9.241a9.24,9.24,0,1,1-.282,14.558Zm5.775-14.783a6.01,6.01,0,1,1-5.775,6.005A5.9,5.9,0,0,1,938.267,388.628Z" transform="translate(-929.259 -378.003)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                        <g id="Group_398" data-name="Group 398" transform="translate(62.831 7.391)">
                          <path id="Path_475" data-name="Path 475" d="M1556.54,450.465h3.234v8.315h-.014c.009.153.014.307.014.462v16.631h-3.7v-9.241a9.24,9.24,0,1,1,.282-14.558Zm-5.774,14.783a6.01,6.01,0,1,1,5.774-6.006A5.9,5.9,0,0,1,1550.765,465.248Z" transform="translate(-1541.295 -450.003)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <text id="Betaalverzoek" transform="translate(186 2938.691)" fill="#1d5c84" font-size="16" font-family="WorkSans-Bold, Work Sans" font-weight="700"><tspan x="0" y="0">Betaalverzoek</tspan></text>
  </g>
</svg>

 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>